import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding-right: 60px;
  padding-left: 60px;
  margin-right: auto;
  margin-left: auto;

  margin-top: ${props => props.marginTop};

  flex: 1;

  @media (max-width: 1200px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @media (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: 767px) {
    margin-top: ${props => props.marginTop || '30px'};
  }
`;

const Container = ({ children, marginTop }) => (
  <Wrapper marginTop={marginTop}>{children}</Wrapper>
);

export default Container;
