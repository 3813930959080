import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

// Context
import { StoreContext } from '../components/context/store-context';

// Components
import Container from '../components/global/container';
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';

const ContactContainer = styled.div`
  padding: 60px 0 0 0;

  @media (max-width: 768px) {
    padding: 30px 0 0 0;
  }
`;

const OrderConfirmation = ({ data }) => {
  // const { clearCheckout, checkout } = useContext(StoreContext);

  // useEffect(() => {
  //   if (checkout.lineItems.length !== 0) {
  //     localStorage.setItem('mt_shopify_checkout_id', null);
  //     clearCheckout();
  //   }
  // }, [checkout]);

  return (
    <>
      <Helmet title={`Order Confirmation – Mourne Textiles`} />
      <Container>
        <Row>
          <Col col={12}>
            <ContactContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.prismicOrderConfirmation.data.text.html,
                }}
              />
            </ContactContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    prismicOrderConfirmation {
      data {
        text {
          html
        }
      }
    }
  }
`;

export default OrderConfirmation;
